
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import IconLoader from '@pixcap/ui-library/components/Icons/IconLoader.vue';
	@Component({
		name: 'LoadingState',
		components: {
			IconLoader,
		},
	})
	export default class LoadingState extends Vue {
		@Prop() readonly message: string;
		@Prop({ default: 35 }) size: number;
	}
